import { BrowserRouter, Routes, Route } from "react-router-dom";
import useWindowDimensions from "./hooks/useWindowDimensions";
import { Suspense, lazy, useEffect, useState } from "react";

const delay = (promise) => {
  return new Promise((resolve) => {
    setTimeout(resolve, 1000);
  }).then(() => promise);
};

const Navbar = lazy(() => import("./components/Navbar.js"));
const HomePage = lazy(() =>
  delay(import("./pages/HomePage.js"))
);
const TimelinePage = lazy(() => import("./pages/TimelinePage.js"));
const DigitalTwinPage = lazy(() => import("./pages/DigitalTwinPage.js"));
const CompareYour = lazy(() => import("./pages/CompareYour.js"));
const FooterPage = lazy(() => import("./pages/FooterPage.js"));

function App() {
  const { width } = useWindowDimensions();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (window.location.pathname.includes("index.html")) {
      window.location.replace("https://www.upu.io");
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <main style={{ width: width }} className="App overflow-hidden">
              <Suspense>
                <Navbar />
              </Suspense>
              <Suspense fallback={<div className="preloader"></div>}>
                <HomePage />
              </Suspense>
              <Suspense>
                <DigitalTwinPage />
              </Suspense>
              <Suspense>
                <TimelinePage />
              </Suspense>
            
              <Suspense>
                <CompareYour />
              </Suspense>
              <Suspense>
                <FooterPage />
              </Suspense>

              
            </main>
          }
        />
        {/* <Route path="/investor" element={<ForInvester />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
