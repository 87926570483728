// const tr = {
//   buttons: {
//     askForDemo: "Demo Talep Et",
//     login: "Giriş",
//   },
//   labels: {
//     name: "İsim",
//     lastname: "Soyisim",
//     nameLastname: "İsim Soyisim",
//     email: "E-posta",
//     phone: "Telefon",
//     company: "Firma",
//     workTitle: "Ünvan",
//     address: "Konu",
//     adress: "Adres",
//     message: "Mesaj",
//     solution: "Çözümü",
//     calculate: "Hesabı",
//     app: "Uygulaması",
//     more: "hakkında daha fazla",
//   },
//   navbar: {
//     home: "Anasayfa",
//     solutions: "Çözümler",
//     whyUpu: "Neden upu ?",
//     products: "Ürünler",
//     contact: "İletişim",
//   },
//   heroSection: {
//     onlyOneDevice: "Tek bir donanımla",
//     title: "ile Fabrikanızın Dijital İkizini Hemen Oluşturun",
//     subTitle:
//       "fabrikanızdaki tüm makinelerin model, yıl, tip farketmeksizin eş zamanlı yahut arşivlenmiş tüm performans, bakım, kayıp, enerji kullanımı verilerine, üretim personelinizin performans analizlerine ve üretim datalarınıza dilediğiniz heryerden bilgisayarınız , tabletiniz veya telefonunuzla 7/24 erişin.",
//   },
//   carbon: {
//     title: "Dünyaya Bırakılan İzleri Takip Ediyoruz",
//     subtitle:
//       "yepyeni bir teknoloji ile ürününüzün karbon ayak izini otomatik olarak hesaplar.",
//   },
//   solutions: {
//     tag: "Çözümlerimiz",
//     title: "Makineleriniz, çalışanlarınız ve ürünleriniz elinizin altında",
//   },

//   whyUpu: {
//     tag: "Neden upu ?",
//     title:
//       "Çünkü upu.io Üretiminizi Endüstri 4.0'a Ulaşatırabilecek En Kestirme Yol",
//     wireless:
//       "Bağlantı ethernet, Wi-Fi, hücresel veya yerel ağlar aracılığıyla kurulur.",
//     plugAndPlay:
//       "Makinelere müdahale ve entegrasyon olmadan, takılır takılmaz kullanıma hazırdır.",
//     quickSetup: "Bir günde fabrikanızı dijitalleştirme imkanı verir.",
//     compatibility:
//       "Sensör ve ışık kulesi her marka ve modelde, yeni ve eski her türlü makinaya uygulanabilir.",
//     cheap: "Mühendislike entegrasyonu, kablo ve kurulum maliyeti gerektirmez.",
//     accessibility:
//       "Telefon, tablet, bilgisayar fark etmeksizin her türlü cihazdan görüntülenebilir ve yönetilebilir.",
//     cloudBase:
//       "İstediğiniz yerden ve cihazdan anında güvenli bağlantı sağlayabilir ve verilerinizi güvenle saklayabilirsiniz.",
//     endToEnd:
//       "Donanım ve yazılım çözümlerinin bir arada olması tüm sorunlarınızı tek bir platformda çözer.",
//     touchlessSetup:
//       "Makinelerinizin elektronik sistemlerine müdahale etmeden kendi sistemiyle veri üretir.",

//     wirelessTitle: "Kablosuz",
//     plugAndPlayTitle: "Tak Kullan",
//     quickSetupTitle: "Hızlı Kurulum",
//     compatibilityTitle: "Tüm Makinelere Uyumlu",
//     cheapTitle: "Uygun Maliyet",
//     accessibilityTitle: "Tüm Cihazlardan Ulaşım",
//     cloudBaseTitle: "Bulut Tabanlı",
//     endToEndTitle: "Uçtan Uca Çözüm",
//     touchlessSetupTitle: "Müdahalesiz Kurulum",
//     easiest: "Easiest Way to Digitilize Your Factory",
//     easiestText1: "Rebuild your business with next-generation technologies.",
//     easiestText2:
//       " , a single IIoT device that is simply installed on all old and new machines in your factory without any electrical interference, is easily installed and then you can access all your machines (",
//     easiestText3: "), your personnel (",
//     easiestText4: ") and your products (",
//     easiestText5:
//       "). You can access and manage all data from anywhere via your computer, phone or tablet.",
//   },
//   products: {
//     tag: "Ürünlerimiz",
//     title: "upu.io'nun Üretiminizi Endüstri 4.0'a Ulaştıracak Ürünleri",
//   },
//   allProducts: {
//     title: "Uçtan Uca IIOT Çözümü",
//     tower:
//       "Upu Tower, tüm makine verilerini yapay zeka destekli sensör fusing ile işler ve bir ağ geçidine (gateaway) ihtiyaç duymadan, makinelerinize müdahale etmeden kendi sensör setleri ile size kolayca bağlanarak çalışanlara iletir.",
//     display:
//       "Geleneksel parça tanımlama kartları yerine, e-ink teknolojisi ile geliştirilen NFC akıllı etiket ürünü.",
//     lite: "upu.tower'ın ışık kulesi özelliklerine ihtiyaç duymadan, tüm durum verilerini otomatik olarak oluşturur ve ethernet veya kablosuz ağ üzerinden aktarır.",
//     sense: "Tüm makinelerle uyumlu IIOT sensör setleri.",

//     current:
//       "4-20 mA analog çıkışlı 0-500 A aralıklı hassas, akıllı akım sensörü.",
//     ldr: "Ayarlanabilir analog ve dijital çıkışlara sahip akıllı IIOT ışık şiddeti sensörü.",
//     prox: "PNP ve NPN akıllı IIOT yakınlık sensörü.",
//     vibra:
//       "Önleyici bakım faaliyetleri için 4-20 mA çıkışlı yapay zeka destekli IIOT titreşim sensörü.",
//     temp: "4-20 mA analog çıkışlı akıllı ve hassas IIOT sıcaklık sensörü.",
//     press: "4-20 mA analog çıkışlı akıllı ve hassas IIOT basınç sensörü.",
//   },
//   tower: {
//     title: "Fabrikanızı kuş bakışı görün",
//     connection: "Bağlantı",
//     technology: "Teknoloji",
//     lightTower: "Işık Kulesi",
//     integratedSystem: "Entegre Sistem",
//     wifiEthernet: "Wifi,İnternet, Birleşik Ad Alanı",
//     digitalInput: "Dijital Giriş",
//     digitalOutput: "Dijital Çıkış",
//     analogInput: "Analog Giriş",
//     sensorFusing:
//       "Sensör birleştirme, makine öğrenimi ile karar vermeyi sağlar.Tüm sensör verilerini filtreleyerek, birleştirerek ve kendi içinde işleyerek çözüm sunar.",
//     ligtColor: "16 milyon açık renk seçeneği",
//     andonButton: "Andon Düğmesi",
//     subTitle:
//       "upu.tower üzerinde bulunan Wi-Fi modülü ve ethernet girişi ile hem kablosuz hem kablolu bağlantı sağlama özelliğine sahiptir. 4 analog, 4 dijital giriş ile makine verilerinizi format fark etmeksizin sisteme taşır.",
//     dropdownTitle: "Wi-Fi Sinyal Işıklı Kulesi",
//   },
//   sense: {
//     title: "Akıllı IIOT Sensör Ürünleri",
//     current: "Akıllı IIOT Akım Sensörü",
//     ldr: "Akıllı IIOT LDR Sensörü",
//     prox: "Akıllı IIOT Yakınlık Sensörü",
//     vibra: "Akıllı IIOT Titreşim Sensörü",
//     temp: "Akıllı IIOT Sıcaklık Sensörü",
//     press: "Akıllı IIOT Basınç Sensörü",
//   },
//   display: {
//     title: "E-Etiket Ekranı",
//   },
//   lite: {
//     title: "Wi-Fi Veri Kutusu",
//   },
//   dashboard: {
//     tag: "Arayüz Görüntüleri",
//     title: "upu.io Sisteminden Arayüz Görüntüleri",
//   },
//   social: {
//     tag: "Sosyal Medyalarımız",
//     title: "upu.io Hakkında Güncel Bilgiler için Hesaplarımızı Takibe Alın",
//   },
//   footer: {
//     title:
//       "Tek bir donanımla(upu.tower) fabrikanızdaki tüm makinelerin model, yıl, tip farketmeksizin eş zamanlı yahut arşivlenmiş tüm performans, bakım, kayıp, enerji kullanımı verilerine, üretim personelinizin performans analizlerine ve üretim datalarınıza dilediğiniz heryerden bilgisayarınız , tabletiniz veya telefonunuzla 7/24 erişin.",
//     keepInTouch: "İletişimde Kalın",
//     rightsReserved: "© 2022 upu.io Tüm Hakları Saklıdır.",
//   },
//   solutionsModal: {
//     //machine
//     twin: "upu.twin ile fabrikanızı kolayca çizebilir, makinelerinizi kurabilir, üretim aşamasını kuş bakışı görebilir, tüm verilerinizi anlık ve geriye dönük analiz edebilirsiniz.",
//     kpi: "Firma İçin: *Teorik Üretim Süresi *Planlı Üretim Süresi, Her Proses ve Makine için : *Uygunluk *Performans *Kullanım Her Personel için : *Performans Verilerinizi,vardiya özelinde, anlık, günlük, haftalık ve yıllık olarak hesaplar. Ayrıca arşivleyerek, bu verileri sizin için analiz eder. ",
//     energy:
//       "Her bir ekipman için anlık, günlük, haftalık, aylık ve yıllık bazda elektrik enerjisi tüketimine yönelik analizler ve çözümler verilir.",
//     loss: "Personelin performans kayıplarının, makinelerinizin kullanılabilirlik kayıplarının, temel nedenleri ve bu kayıplardan kaynaklanan maliyetleri sizin için otomatik olarak listelenir ve raporlanır.",
//     report:
//       "Sizin için otomatik olarak günlük, haftalık ve aylık raporlar üretir. Ayrıca çok kolay ve kullanıcı dostu bir rapor oluşturma ekranı ile istediğiniz tüm analiz verilerine dilediğiniz zaman ulaşabilirsiniz.",
//     calibrate: "",
//     shift:
//       "Şirketinizin çalışma vardiyalarını yöneterek her bir vardiya için performansı karşılaştırmanıza ve izlemenize olanak tanıyan yeni nesil vardiya yönetimi çözümleri sunar.",
//     //person
//     point:
//       "Personelinizin çalışma performansları ile sistem üzerinden otomatik olarak oluşturulacak, performans KPI'larını liderlik tablosu ile günlük, haftalık, aylık, yıllık olarak karşılaştırabileceğiniz ve onları prim ile ödüllendirebileceğiniz bir puanlama sistemidir.",
//     care: "upu.care tarafından sağlanan akıllı bakım izleme çözümü ile ekipmanınızın bakımını gerçek zamanlı olarak takip edebilir ve planlayabilirsiniz.",
//     message:
//       "Şirket içi kurumsal mesajlaşma çözümü ile tüm personelinize anında ve güvenli bir şekilde ulaşabilirsiniz",
//     talent:
//       "Personelinizin eğitim ve yeteneklerini arşivleyerek gelişimlerini takip edebilirsiniz.",
//     poll: "Personellerinizin memnuniyetini arttırmak için onlara özel anketler oluşturabilir, şirket içi anket sistemi sayesinde öneri ve şikayetlere anında yanıt verebilirsiniz.",
//     notify:
//       "Firmanınızda gerçekleşen olay ve durumları kurumsal bildirim sistemi ile seçtiğiniz çalışanlara uygulama ve web arayüzünden gönderebilirsiniz.",
//     task: "Firmanızda çalışan elemanlarınız arasında denetlenebilir ve gözlemlenebilir görev dağılımını bu hizmet ile kolaylıkla gerçekleştirebilirsiniz.",
//     attend:
//       "Çalışanlarınızın geliş ve gidiş saatlerini, izin durumlarını görüntüleyebilir, ayrıca bir çalışanın aylık çalışma saatlerini raporlayabilirsiniz.",
//     //product
//     part: "",
//     supply: "",
//     cost: "",
//     quality: "",
//     assist: "",
//     api: "",
//   },
//   story: {
//     title: "The Story of",
//     subTitle:
//       "upu.io is a cloud-based next-generation cyber-physical production management platform that will revolutionize your production and change your perspective on manufacturing. It provides end-to-end smart manufacturing solutions to digitize your production process.",
//     text: "The name upu.io is derived from the hoopoe bird, which is the only member of the Upupidae family in the Coraciiformes order, known for its distinctive crest and unique vocalizations. Throughout history, this beautiful bird has been considered sacred in various traditional sources and is known as the messenger bird of King Solomon. We see upu.io as a messenger that will communicate everything to executives (like Solomon) in their own language, even though they may not speak the language of 'bird' (data). '.io' represents the digital dual direction of data (input and output) in our platform.",
//   },
//   modalText: {
//     //machine
//     twin: "Tamamen özgün olarak geliştirilmiş olan bu çözüm paketi, tüm üreticilere uygulanabilir. Aynı zamanda ilk dijital çözüm paketi olan upu.twin’i satın aldığınızda fabrikanızın planını kolaylıkla çizebilmenizi sağlar. Böylece proseslerinize göre makinelerinizi gruplandırabilir ve gerçek konumlarını kuşbakışı görüntüleyebilirsiniz. Fabrikanızı gerçek zamanlı olarak izleme imkanı da sunmaktadır.",
//     kpi: "Firma İçin: *Teorik Üretim Süresi *Planlı Üretim Süresi, Her Proses ve Makine için : *Uygunluk *Performans *Kullanım Her Personel için : *Performans Verilerinizi,vardiya özelinde, anlık, günlük, haftalık ve yıllık olarak hesaplar. Ayrıca arşivleyerek, bu verileri sizin için analiz eder.",
//     energy:
//       "Her bir ekipman için anlık, günlük, haftalık, aylık ve yıllık bazda elektrik enerjisi tüketimine yönelik analizler ve çözümler verilir.",
//     care: "upu.care tarafından sağlanan akıllı bakım izleme çözümü ile ekipmanınızın bakımını gerçek zamanlı olarak takip edebilir ve planlayabilirsiniz.",
//     loss: "Personelin performans kayıplarının, makinelerinizin kullanılabilirlik kayıplarının, temel nedenleri ve bu kayıplardan kaynaklanan maliyetleri sizin için otomatik olarak listelenir ve raporlanır.",
//     report:
//       "Sizin için otomatik olarak günlük, haftalık ve aylık raporlar üretir. Ayrıca çok kolay ve kullanıcı dostu bir rapor oluşturma ekranı ile istediğiniz tüm analiz verilerine dilediğiniz zaman ulaşabilirsiniz.",
//     shift:
//       "Şirketinizin çalışma vardiyalarını yöneterek her bir vardiya için performansı karşılaştırmanıza ve izlemenize olanak tanıyan yeni nesil vardiya yönetimi çözümleri sunar.",

//     //person
//     point:
//       "Çalışanlarınızın iş performanslarını ve sistem üzerinden otomatik olarak oluşturulacak performans KPI'larını liderlik tablosu ile haftalık, aylık, yıllık ve günlük olarak karşılaştırabileceğiniz ve ikramiye ile ödüllendirebileceğiniz bir puanlama sistemidir.",
//     care: "With the smart maintenance tracking solution provided by upu.care, you can keep track of and schedule the upkeep of your equipment in real-time.",
//     message:
//       "Şirket içi kurumsal mesajlaşma çözümü ile tüm personelinize anında ve güvenli bir şekilde ulaşabilirsiniz.",
//     talent:
//       "Personelinizin gelişimini, eğitimlerini ve yeteneklerini arşivleyerek takip edebilirsiniz.",
//     poll: "Çalışan memnuniyetini artırmak için çalışanlarınıza özel anketler oluşturabilir, şirket içi anket sistemi sayesinde öneri ve şikayetlere anında yanıt verebilirsiniz.",
//     notify:
//       "Bu çözüm, sisteminizdeki tüm değişiklikleri tek bir yerde görmenizi ve tüm şirket çalışanlarının görebileceği duyuruları paylaşmanızı sağlar.",
//     task: "Personellerinize görev atayabilir, bağlı oldukları departmanlara göre görev grupları oluşturabilir ve bu görevlerin adım adım ilerleyişini takip edebilirsiniz.",
//     attend:
//       "Çalışanlarınızın işe geliş ve gidiş saatlerini, izin durumlarını görüntüleyebilir, ayrıca bir çalışanın aylık çalışma saatlerini raporlayabilirsiniz.",
//     //product
//     cCount:
//       "upu.io'nun çevresel ve teknolojik olarak en büyük yeniliği, üretilen ürünlerin üretim anındaki kaynak tüketim verilerini makineden bağımsız olarak elde edebilmesi ve karbon ayak izini ürün bazında otomatik olarak hesaplayabilmesidir. Bu, makinelerden alınan verileri tekdüzeleştirmesi ve firmanın enerji, makine, personel ve ürün kaynak verilerini uçtan uca bir yaklaşımla yönetebilmesini sağlayan bir altyapıya ihtiyaç duymadan holistik bir şekilde yapmasıdır.",
//     part: "",
//     supply:
//       "Tedarik süreçlerinizi her yönüyle dijitalleştirir ve bu süreçte tedarikçilerinizi de dijital akıllı üretim süreçlerinize kolayca entegre eder.",
//     cost: "",
//     quality:
//       "Üretim operasyonlarınızın tüm kalite kontrol süreçleri dijitalleştirilrek takip ediir. Kalite kontrol verileriniz ürün merkezli otomatik depolanır ve analiz edilir.",
//     assist:
//       "Tümüyle bulut tabanlı olan upu.io yazılımı istediğiniz her an 7/24 destek imkanı sağlar. Eğitim birimimiz, personelleriniz yetkinliklerini kazanana kadar yanınızda olacaktır.",
//     api: "Tüm ERP ve iş süreçleri yazılımları ile uyumluluğu geliştirecek altyapılar upu.io ile müşteri hizmetindedir.",
//     count:
//       "upu.io'nun çevresel ve teknolojik olarak en büyük yeniliği, üretilen ürünlerin üretim anındaki kaynak tüketim verilerini makineden bağımsız olarak elde edebilmesi ve karbon ayak izini ürün bazında otomatik olarak hesaplayabilmesidir. Bu, makinelerden alınan verileri tekdüzeleştirmesi ve firmanın enerji, makine, personel ve ürün kaynak verilerini uçtan uca bir yaklaşımla yönetebilmesini sağlayan bir altyapıya ihtiyaç duymadan holistik bir şekilde yapmasıdır.",
//     stock:
//       "Aktif stok kontrolünüzü dijitalleştirir. Anlık stok ve ara mamül takip edilebilir.",
//     order:
//       "Ürünlerinizin siparişten teslimata kadar geçen tüm süreçleri kamban yöntemi ve geliştirilmiş  çözümümüz ile birlikte uçtan uca akıllı üretime hazır hale getirilir. ",
//   },
//   machinePackage: {
//     title: "Makine İzleme Paketi",
//     subTitle:
//       "Tüm makinelerinizin performans verilerine, enerji analizlerine, kayıp bilgilerine ulaşmanın yanı sıra bakımlarınızı yönetebileceksiniz.",
//     twin: "Dijital İkiz",
//     kpi: "Anahtar Performans Göstergesi",
//     energy: "Enerji Sarfiyat Analizi",
//     loss: "Çalışma Kayıplarının Takibi",
//     report: "Periyodik Raporlama Servisi",
//     care: "Bakım Takibi",
//     calibrate: "Ekipman Kalibrasyon Takibi",
//     shift: "Vardiya Yönetimi",
//   },
//   personPackage: {
//     title: "Personel Yönetim Paketi",
//     subTitle:
//       "Tüm çalışanlarınızın performans verilerine karşılaştırmalı olarak mesajlaşma, anket, öneri gibi hazır modüllerle birlikte erişebileceksini",
//     point: "Personel Performans Takibi",
//     care: "Bakım Yönetimi",
//     message: "Kurumsal Mesajlaşma Uygulaması ",
//     talent: "Yetenek Yönetimi",
//     attend: "Personel Devamlılığı Takibi",
//     poll: "Kurumsal Anket Sistemi",
//     notify: "Anında Bildirim Sistemi",
//     task: "Görev Yönetimi",
//   },
//   productPackage: {
//     title: "İmalat Yönetim Paketi",
//     subTitle:
//       "Üretiminizi tüm yönleriyle kapsayan üretim takibi, tedarik yönetimi, maliyet analizi gibi birçok özelliğiyle tüm upu.io imkanlarını kullanabileceksiniz",
//     cCount: "Carbon Ayak izi Hesaplama",
//     part: "Üretim Yönetimi Sistemi",
//     supply: "Tedarik Zinciri Yönetimi",
//     cost: "Üretim Maliyeti Hesaplama",
//     quality: "Kalite Operasyonları Yönetimi",
//     assist: "7/24 Servis ve Eğitim",
//     api: "API Servisi",
//     stock: "Stok Yönetimi",
//     order: "Sipariş Yönetimi",
//     count: "Karbon Ayak İzi Hesaplama",
//   },
// };

// export default tr;
const tr = {
  buttons: {
    askForDemo: "Request a Demo",
    login: "Login",
  },
  labels: {
    name: "Name",
    lastname: "Lastname",
    nameLastname: "Name & Lastname",
    email: "E-mail",
    phone: "Phone",
    company: "Company",
    workTitle: "Title",
    address: "Subject",
    adress: "Adress",
    message: "Message",
    solution: "Solution",
    calculate: "Calculation",
    app: "App",
    more: "more about",
  },
  navbar: {
    home: "Home",
    solutions: "Solutions",
    whyUpu: "Why upu ?",
    products: "Products",
    contact: "Contact",
  },
  heroSection: {
    onlyOneDevice: "With a single hardware device",
    title: "Create Digital Twin of Your Factory with",
    subTitle:
      ",you can access all performance, maintenance, loss, and energy usage data, production personnel analysis, and production data of all equipment in your factory, regardless of model, year, or type, simultaneously or archived, from any computer, tablet, or phone, at any time.",
  },
  carbon: {
    title: "We Follow the Traces Left in the World",
    subtitle:
      "automatically calculates the carbon footprint of your product with a brand new technology",
  },
  solutions: {
    tag: "Solutions",
    title: "Your machines, employees, and products are all at your fingertips.",
  },
  whyUpu: {
    tag: "Why upu ?",
    title:
      "Because upu.io is the quickest way to transition your production to Industry 4.0",
    wireless:
      "Connection is established via ethernet, WI-FI, cellular or local networks",
    plugAndPlay:
      "It is ready to use as soon as it is installed, without any intervention or machine integration",
    quickSetup: "It allows you to digitize your factory in a single day",
    compatibility:
      "Any brand and model of machinery, both new and old, can use a sensor and light tower",
    cheap:
      "Installation costs for wiring are not necessary for engineering integration.",
    accessibility: "Any device can be used to view and manage it",
    cloudBase:
      "Anywhere, using any device, you can instantly establish a secure connection, storing your data safely",
    endToEnd:
      "Your issues can be resolved on a single platform by combining hardware and software solutions",
    touchlessSetup:
      "Without interfering with the electronic systems of your machines, it produces data using its own system",

    wirelessTitle: "Wireless",
    plugAndPlayTitle: "Plug and Play",
    quickSetupTitle: "Quick Setup",
    compatibilityTitle: "Compatible with All Machines",
    cheapTitle: "Affordable Prices",
    accessibilityTitle: "Access from Any Device",
    cloudBaseTitle: "Cloud Based",
    endToEndTitle: "End-to-End Solution",
    touchlessSetupTitle: "Uninterrupted Setup",
    easiest: "Easiest Way to Digitize Your Factory",
    easiestText1: "Rebuild your business with next-generation technologies.",
    easiestText2:
      " , a single IIoT device that is simply installed on all old and new machines in your factory without any electrical interference, is easily installed and then you can access all your machines (",
    easiestText3: "), your personnel (",
    easiestText4: ") and your products (",
    easiestText5:
      "). You can access and manage all data from anywhere via your computer, phone or tablet.",
  },
  products: {
    tag: "Products",
    title: "Upu products that will move your manufacturing to Industry 4.0",
  },
  allProducts: {
    title: "End-to-end IIOT solution",
    tower:
      "Upu Tower, processes all machine data with artificial intelligence supported sensor fusing and transmits it to employees without the need for a gateway, by easily connecting to you with its own sensor sets without interfering with your machines.",
    display:
      "Instead of traditional part identification cards, NFC smart tag product developed by  e-ink technology.",
    lite: "Without requiring the light tower features of upu.tower, it automatically generates all status data and transfers it over ethernet or wireless.",
    sense: "IIOT sensor sets are compatible with all machines",

    current:
      "0-500 A interleaved sensitive intelligent current sensor with 4-20 mA analog outputs.",
    ldr: "Intelligent IIOT light sensor with adjustable analog and digital outputs.",
    prox: "PNP and NPN intelligent IIOT proximity sensor.",
    vibra:
      "Artificial intelligence supported IIOT Vibration sensor with 4-20 mA output for predictive maintenance activities.",
    temp: "Intelligent and sensitive IIOT temperature sensor with 4-20 mA analog output.",
    press:
      "Intelligent and sensitive IIOT pressure sensor with 4-20 mA analog output.",
  },
  tower: {
    title: "Get a bird's eye view of your manufacturing",
    connection: "Connection",
    technology: "Technology",
    lightTower: "Light Tower",
    integratedSystem: "Integrated System",
    wifiEthernet: "Wifi, Ethernet, Unified NameSpace",
    digitalInput: "Digital Input",
    digitalOutput: "Digital Output",
    analogInput: "Analog Input",
    sensorFusing:
      "Sensor fusing, provides a machine learning decision-making solution by filtering, combining, and processing all sensor data within itself.",
    ligtColor: "16M light color options",
    andonButton: "Andon Button",
    subTitle:
      "It has the feature of providing both wireless and wired connection with the Wi-Fi module and ethernet input on upu.tower. With 4 analog and 4 digital inputs, the machine carries your data to the system regardless of format.",
    dropdownTitle: "Wi-Fi Sensor Fusing Signal Light Tower",
  },
  sense: {
    title: "Plug and Play Smart IIOT Sensor Products",
    current: "Intelligent IIOT Current Sensor",
    ldr: "Intelligent IIOT LDR Sensor",
    prox: "Intelligent IIOT Proximity Sensor",
    vibra: "Intelligent IIOT Vibration Sensor",
    temp: "Intelligent IIOT Temperature Sensor",
    press: "Intelligent IIOT Pressure Sensor",
  },
  display: {
    title: "NFC E-Label Display",
  },
  lite: {
    title: "Wi-Fi Data Box",
  },
  dashboard: {
    tag: "Interface Images",
    title: "Interface Images from upu.io System",
  },
  social: {
    tag: "Social Medias",
    title: "Follow Our Accounts For Up-to-Date Information About upu.io",
  },
  footer: {
    title:
      "With a single hardware device(upu.tower) ,you can access all performance, maintenance, loss, and energy usage data, production personnel analysis, and production data of all equipment in your factory, regardless of model, year, or type, simultaneously or archived, from any computer, tablet, or phone, at any time.",
    keepInTouch: "Keep in touch",
    rightsReserved: "© 2022 upu.io All Rights Reserved.",
  },
  solutionsModal: {
    //machine
    twin: "With upu.twin, you can easily create your factory, set up your machines, see your production from above, instantly and retroactively analyze all of your data.",
    kpi: "For The Entire Company : *Teorical Production Time, *Planned Production Time. For Every Process and Machines : *Avaibility *Performance *Utilization For Every Personnel : *Performance It calculates your data momentary, per shift, daily, weekly, and yearly, archives, and analyzes this data for you. ",
    energy:
      "Analysis and solutions for electrical energy consumption on a moment-by-moment, daily, weekly, monthly, and annual basis are given for each equipment.",
    loss: "The performance and usability losses of your employees and machines, their root causes, and the cost incurred due to these losses are automatically listed and reported for you.",
    report:
      "It automatically generates daily, weekly and monthly reports for you. In addition, you can access all the analysis data you want at any time with a very easy and user-friendly report generation screen.",
    calibrate: "",
    shift:
      "It provides a new generation of shift management solutions that allow you to compare and track performance for each of the shifts by managing your company's working shifts.",
    //person
    point:
      "It is a pointing system where you can compare your employees’ work performance and the performance KPI to be automatically generated through the system, with the leaderboard on a weekly, monthly, yearly, and daily basis, and reward them with a bonus.",
    care: "With the smart maintenance tracking solution provided by upu.care, you can keep track of and schedule the upkeep of your equipment in real-time.",
    message:
      "With the in-company corporate messaging solution, you can reach all your personnel instantly and securely.",
    talent:
      "You can keep track of your personnel’s advancement by archiving their training and abilities.",
    poll: "You can create customized surveys for your employees to increase employee satisfaction, and you can respond to suggestions and complaints right away thanks to the in-company survey system.",
    notify:
      "You can create notifications for events and actions that occured in your company. Also you can send those notifications to personnels that you can specify.",
    task: "You can manage all the tasks that given in your company or department easily with this service.",
    attend:
      "You can view the arrival and departure times, as well as the status of your employees' leaves, and you can also report an employee's monthly working hours.",
    //product
    part: "",
    supply:
      "It digitizes your procurement processes in every aspect and seamlessly integrates your suppliers into your digital smart manufacturing processes.",
    cost: "",
    quality:
      "All quality control processes of your manufacturing operations are digitized and tracked. Your quality control data is automatically stored and analyzed on a product-centric basis.",
    assist:
      "The entirely cloud-based upu.io software provides 24/7 support whenever you need it. Our training team will be with you until your personnel acquire the necessary competencies.",
    api: "The infrastructure that will improve compatibility with all ERP and business process software is available to customers through upu.io.",
    countT:
      "The greatest environmental and technological innovation of upu.io is its ability to obtain resource consumption data during production independent of machines, and automatically calculate the product-level carbon footprint. This is achieved by standardizing the data obtained from machines and enabling holistic management of energy, machinery, personnel, and product resource data without the need for a separate infrastructure.",
    stockT:
      "It digitizes your active inventory control. Real-time tracking of inventory and intermediate products is possible.",
    orderT:
      "All the processes from order to delivery of your products are prepared for end-to-end smart manufacturing with the kanban method and our enhanced solution.",
  },
  pyramid: {
    head: "We Are Rebuilding the Automation Pyramid",
    text: "The Traditional Automation Pyramid defines the functional hierarchy of your company's level of digitalization (ISA-95) in 5 levels. According to this defined automation pyramid, engineering applications are required between each automation level in factories. This means that every step to raise the automation level comes with high costs for companies that want to improve their automation level. This is where upu.io's end-to-end innovative solution comes in and helps companies looking to digitize and enter the Industry 4.0 world by making their work easier with a cloud-based next-generation architecture at low cost.",
  },
  story: {
    title: "The Story of",
    subTitle:
      "upu.io is a cloud-based next-generation cyber-physical production management platform that will revolutionize your production and change your perspective on manufacturing. It provides end-to-end smart manufacturing solutions to digitize your production process.",
    text: "The name upu.io is derived from the hoopoe bird, which is the only member of the Upupidae family in the Coraciiformes order, known for its distinctive crest and unique vocalizations. Throughout history, this beautiful bird has been considered sacred in various traditional sources and is known as the messenger bird of King Solomon. We see upu.io as a messenger that will communicate everything to executives (like Solomon) in their own language, even though they may not speak the language of 'bird' (data). '.io' represents the digital dual direction of data (input and output) in our platform.",
  },
  modalText: {
    //machine
    twin: "This solution package, developed entirely in-house, is applicable to all manufacturers. Additionally, upu.twin, the first digital solution package, allows you to easily draw your factory plan. This way, you can group your machines according to your processes and view their actual locations at a bird's-eye view. It also provides real-time monitoring of your factory.",
    kpi: "For The Entire Company : *Teorical Production Time, *Planned Production Time. For Every Process and Machines : *Avaibility *Performance *Utilization For Every Personnel : *Performance It calculates your data momentary, per shift, daily, weekly, and yearly, archives, and analyzes this data for you. ",
    energy:
      "Analysis and solutions for electrical energy consumption on a moment-by-moment, daily, weekly, monthly, and annual basis are given for each equipment.",
    care: "With the smart maintenance tracking solution provided by upu.care, you can keep track of and schedule the upkeep of your equipment in real-time.",
    loss: "The performance and usability losses of your employees and machines, their root causes, and the cost incurred due to these losses are automatically listed and reported for you. ",
    report:
      "It automatically generates daily, weekly and monthly reports for you. In addition, you can access all the analysis data you want at any time with a very easy and user-friendly report generation screen.",
    shift:
      "It provides a new generation of shift management solutions that allow you to compare and track performance for each of the shifts by managing your company's working shifts.",

    //person
    point:
      "It is a pointing system where you can compare your employees’ work performance and the performance KPI to be automatically generated through the system, with the leaderboard on a weekly, monthly, yearly, and daily basis, and reward them with a bonus.",
    care: "With the smart maintenance tracking solution provided by upu.care, you can keep track of and schedule the upkeep of your equipment in real-time.",
    message:
      "With the in-company corporate messaging solution, you can reach all your personnel instantly and securely.",
    talent:
      "You can keep track of your personnel’s advancement by archiving their training and abilities.",
    poll: "You can create customized surveys for your employees to increase employee satisfaction, and you can respond to suggestions and complaints right away thanks to the in-company survey system.",
    notify:
      "You can create notifications for events and actions that occured in your company. Also you can send those notifications to personnels that you can specify.",
    task: "You can manage all the tasks that given in your company or department easily with this service.",
    attend:
      "You can view the arrival and departure times, as well as the status of your employees' leaves, and you can also report an employee's monthly working hours.",
    //product
    cCount:
      "The greatest environmental and technological innovation of upu.io is its ability to obtain resource consumption data during production independent of machines, and automatically calculate the product-level carbon footprint. This is achieved by standardizing the data obtained from machines and enabling holistic management of energy, machinery, personnel, and product resource data without the need for a separate infrastructure.",
    part: "",
    supply:
      "It digitizes your procurement processes in every aspect and seamlessly integrates your suppliers into your digital smart manufacturing processes.",
    cost: "",
    quality:
      "All quality control processes of your manufacturing operations are digitized and tracked. Your quality control data is automatically stored and analyzed on a product-centric basis.",
    assist:
      "The entirely cloud-based upu.io software provides 24/7 support whenever you need it. Our training team will be with you until your personnel acquire the necessary competencies.",
    api: "The infrastructure that will improve compatibility with all ERP and business process software is available to customers through upu.io.",
    count:
      "The greatest environmental and technological innovation of upu.io is its ability to obtain resource consumption data during production independent of machines, and automatically calculate the product-level carbon footprint. This is achieved by standardizing the data obtained from machines and enabling holistic management of energy, machinery, personnel, and product resource data without the need for a separate infrastructure.",
    stock:
      "It digitizes your active inventory control. Real-time tracking of inventory and intermediate products is possible.",
    order:
      "All the processes from order to delivery of your products are prepared for end-to-end smart manufacturing with the kanban method and our enhanced solution.",
  },
  machinePackage: {
    title: "Machine Monitoring Package",
    subTitle:
      "You will be able to manage your maintenance in addition to accessing performance data, energy analysis, and loss information for all of your machines.",
    twin: "Digital Twin",
    kpi: "Key Performance Indicator",
    energy: "Energy Analizing",
    loss: "Work Loss Tracking",
    report: "Periodic Report Service",
    calibrate: "Equipment Calibration",
    care: "Maintenance Management",
    shift: "Shift Management",
  },
  personPackage: {
    title: "Personnel Management Package",
    subTitle:
      "You will have access to your employees' performance data with ready-made modules such as messaging, surveys, and suggestions, allowing you to compare them.",
    point: "Personnel Effecient Scoring",
    care: "Maintanence Management Solution",
    message: "Enterprise Messaging App",
    talent: "Personnel Talent Management",
    attend: "Personnel Attendence Control",
    poll: "Enterprise Survey System",
    notify: "Instant Notification  System",
    task: "Task Management",
  },
  productPackage: {
    title: "Production Management Package",
    subTitle:
      "You will be able to take advantage of all of upu.io's features, which cover every aspect of your production and include production tracking, supply management, and cost analysis.",
    cCount: "Carbon Footprint Calculation",
    part: "Production Management Solution",
    supply: "Supply Chain Management",
    cost: "Production Cost Solution",
    quality: "Quality Operations Support",
    assist: "24/7 Service and Education",
    api: "API Service",
    stock: "Stock Management",
    order: "Production Management",
    count: "Carbon Footprint Calculation",
  },
};

export default tr;
